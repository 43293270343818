<template>
  <div class="row no-gutters">
    <div class="col-xl-4 order-xl-1 pl-xl-2">
      <div class="card mb-3">
        <div class="card-header bg-light btn-reveal-trigger d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Детали заказа</h5>
          <router-link 
            class="btn btn-link btn-sm btn-reveal text-600" 
            to="/plans">
            <span class="fas fa-pencil-alt"></span>
          </router-link>
        </div>
        <div class="card-body">
          <table class="table table-borderless fs--1 mb-0">
            <tr class="border-bottom">
              <th class="pl-0 pt-0">Подписка по плану "{{orderDetails.plan}}" {{orderDetails.planDescription}}
                <div class="text-400 font-weight-normal fs--2">{{orderDetails.subscriptionDesc}}</div>
              </th>
              <th class="pr-0 text-right pt-0">{{orderDetails.total | currency}} <small>₽</small></th>
            </tr>
            
            <tr class="border-bottom">
              <th class="pl-0">Экономия: <span class="text-info fs-0">{{((orderDetails.economy)*100).toFixed(0)}}<small>%</small></span></th>
              <th class="pr-0 text-right">{{(orderDetails.total * orderDetails.economy).toFixed(0) | currency}} <small>₽</small></th>
            </tr>
            <!-- <tr>
              <th class="pl-0 pb-0">Итого</th>
              <th class="pr-0 text-right pb-0">{{(orderDetails.total - (orderDetails.total * orderDetails.economy)).toFixed(0) | currency}} <small>₽</small></th>
            </tr> -->
          </table>
        </div>
        <div class="card-footer d-flex justify-content-between bg-100">
          <div class="font-weight-semi-bold">К оплате:</div>
          <div class="font-weight-bold">{{(orderDetails.total - (orderDetails.total * orderDetails.economy)).toFixed(0) | currency}} <small>₽</small></div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 pr-xl-2">
      
      <div class="card mb-3">
        <div class="card-header bg-light">
          <h5 class="mb-0">Способ оплаты</h5>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="col-12">
              <div class="custom-control custom-radio">
                <input class="custom-control-input radio-collapse" type="radio" name="payment-method" id="credit-card" checked="checked" />
                <label class="custom-control-label" for="credit-card">
                  <span class="d-flex align-items-center mb-2 fs-1">Банковской картой</span>
                </label>
              </div>
            </div>
            <div class="col-12 pl-4">
              <div class="row">
                <div class="col-sm-8">
                  <div class="form-row align-items-center">
                    <div class="col">
                      <div class="form-group">
                        <label class="ls text-uppercase text-600 font-weight-semi-bold mb-0">Номер</label>
                        <input class="form-control" type="text" placeholder="•••• •••• •••• ••••">
                      </div>
                    </div>
                  </div>
                  <div class="form-row align-items-center">
                    <div class="col-6">
                      <div class="form-group">
                        <label class="ls text-uppercase text-600 font-weight-semi-bold mb-0">Срок действия</label>
                        <input class="form-control" type="text" placeholder="mm/yyyy">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="ls text-uppercase text-600 font-weight-semi-bold mb-0">
                          CVV
                          <a class="d-inline-block" href="#">
                            <span 
                              class="fa fa-question-circle ml-2" 
                              data-toggle="tooltip" 
                              data-placement="top" 
                              title="Card verification value">
                            </span>
                          </a>
                        </label>
                        <input class="form-control" type="text" placeholder="123" maxlength="3" pattern="[0-9]{3}">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 text-center pt-2 d-none d-sm-block">
                  <div class="rounded p-2 mt-3 bg-100">
                    <div class="text-uppercase fs--2 font-weight-bold">
                      Принимаем все виды карт
                    </div>
                    <img src="@/assets/img/icons/icon-payment-methods-grid.png" alt="" width="120">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row mt-3">
            <div class="col-12">
              <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" name="payment-method" id="paypal" />
                <label class="custom-control-label" for="paypal">
                  <img class="pull-right" src="@/assets/img/icons/icon-paypal-full.png" height="20" alt="">
                </label>
              </div>
            </div>
          </div> -->
          <hr class="border-dashed my-5">
          <div class="row">
            <div class="col-md-7 col-xl-12 col-xxl-7 vertical-line px-md-3 mb-xxl-0">
              <div class="media">
                <img class="mr-3" src="@/assets/img/icons/shield.png" alt="" width="60">
                <div class="media-body">
                  <h5 class="mb-2">Защищенная покупка</h5>
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" id="protection-option-1" type="checkbox" checked>
                    <label class="custom-control-label" for="protection-option-1"> 
                      <strong>Безопасность транзакции </strong>
                      <br class="d-none d-md-block d-lg-none">проходящей в зашифрованном виде
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" id="protection-option-2" type="checkbox" checked>
                    <label class="custom-control-label" for="protection-option-2"> <strong>Полный или частичный возврат, </strong>
                      если сервис Вам не понравится
                    </label>
                  </div>
                  <!-- <a class="fs--1 ml-3 pl-2" href="#!">
                    Learn More
                    <span class="fas fa-caret-right ml-1" data-fa-transform="down-2">    </span>
                  </a> -->
                </div>
              </div>
            </div>
            <div class="col-md-5 col-xl-12 col-xxl-5 pl-lg-4 pl-xl-2 pl-xxl-5 text-center text-md-left text-xl-center text-xxl-left">
              <hr class="border-dashed d-block d-md-none d-xl-block d-xxl-none my-4">
              <div class="fs-2 font-weight-semi-bold">
                К оплате: 
                <span class="text-primary">
                  {{(orderDetails.total - (orderDetails.total * orderDetails.economy)).toFixed(0) | currency}} <small>₽</small>
                </span>
              </div>
              <button 
                class="btn btn-success mt-3 px-5" 
                type="submit"
                @click.prevent="makeTestPayment">
                Оплатить
              </button>
              <p class="fs--1 mt-3 mb-0">
                Нажимая кнопку <strong>Оплатить </strong>Вы подтверждаете согласие с 
                <a href="#!">Условиями предоставления услуг</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Footer from './Footer'

export default {
  metaInfo() {
    return {
      title: this.$title('Оплата')
    }
  },
  mounted() {
    let yandexCheckout = document.createElement('script')
    yandexCheckout.setAttribute('src', 'https://static.yandex.net/checkout/js/v1/')
    document.head.appendChild(yandexCheckout)

  },
  components: {
    appFooter: Footer,
  },
  computed: {
    orderDetails() {
      var orderDetails = {
        plan: '',
        planDescription: '',
        subscriptionTill: '',
        subscriptionDesc: '',
        economy: 0,
        total: 0
      }

      if (!this.$route.query.plan) {
        this.$router.push('/plans')
      }

      switch (this.$route.query.plan) {
        case 'basic': 
          orderDetails = {
            plan: 'Базовый',
            planDescription: 'x 1',
            subscriptionTill: moment().add(1, 'month'),
            subscriptionDesc: 'Подпсика на 1 месяц, действительна по ' + moment().add(1, 'month').format('LL'),
            economy: 0,
            total: 345
          }
          break
        case 'gold': 
          orderDetails = {
            plan: 'Золотой',
            planDescription: '(Базовый x 3)',
            subscriptionTill: moment().add(3, 'months'),
            subscriptionDesc: 'Подпсика на 3 месяца, действительна по ' + moment().add(3, 'months').format('LL'),
            economy: 0.15,
            total: (345 * 3)
          }
          break
        case 'premium': 
          orderDetails = {
            plan: 'Премиум',
            planDescription: '(Базовый x 12)',
            subscriptionTill: moment().add(12, 'months'),
            subscriptionDesc: 'Подпсика на 12 месяцев, действительна по ' + moment().add(12, 'months').format('LL'),
            economy: 0.35,
            total: (345 * 12)
          }
          break
        default:
          this.$router.push('/plans')
      }

      return orderDetails
    }
  },
  methods: {
    makeTestPayment() {
      const checkout = YandexCheckout('633772', 'test_LRcJM7r4Sfsdbun7RV9wQA-nN62wze1nVSxvmkpMU8M')

      checkout.tokenize({
        number: '5555555555554477',
        cvc: '123',
        month: '10',
        year: '21'
      })

      console.log(checkout)

    }
  }
}
</script>